<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card>
      <v-card-title>
        Zonas de atuação
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-toolbar outlined elevation="0">{{ form.leadTypes }}</v-toolbar>
      <v-card-text>
        <v-checkbox
          v-for="leadType in leadTypes"
          :key="leadType.id"
          :label="leadType.description"
          :value="leadType.id"
          v-model="form.leadTypes"
        ></v-checkbox>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { index as indexLeadTypes } from "@/services/lead_types";

export default {
  components: {},

  data() {
    return {
      dialog: false,
      form: {
        leadTypes: [],
      },
      leadTypes: [],
    };
  },

  methods: {
    async getLeadTypes() {
      if (this.leadTypes.length > 0) {
        return;
      }
      this.leadTypes = await indexLeadTypes();
    },

    open() {
      this.dialog = true;
      this.form.serviceType = null;
      this.getLeadTypes();
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>
