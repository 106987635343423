<style lang="scss" scoped>
.filter {
}
.header-container {
  display: flex;

  .col {
    align-content: center;
  }
}

.menu {
}

.filter-table {
  .label {
    max-width: 170px;
    color: rgb(145, 145, 145);
  }
}

.danger {
  color: red !important;
}

.status-chip {
  // width: 100px;
  // justify-content: center;
}
</style>
<template>
  <container class="unities">
    <v-row class="header-container">
      <v-col cols="12" md="4" lg="3">
        <v-text-field
          v-model="filter"
          @keyup.enter="getUnities()"
          :label="__('Pesquisar unidade', 'unities')"
          prepend-inner-icon="mdi-magnify"
          outlined
          class="filter"
          background-color="#fff"
        >
          <template v-slot:append></template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="getUnities()" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-menu
          offset-x
          v-model="advanced_filter"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge :value="advanced_filtered" color="red" dot overlap>
              <v-btn v-bind="attrs" v-on="on" icon small>
                <v-icon v-if="!advanced_filter">mdi-filter-menu-outline</v-icon>
                <v-icon v-else>mdi-filter-minus-outline</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <v-form @submit.prevent="handleAdvanceFilter()">
            <v-card flat class="menu">
              <v-card-title>{{
                __("Filtros avançados", "unities")
              }}</v-card-title>
              <v-card-text>
                <v-simple-table class="filter-table">
                  <tr>
                    <td class="label">
                      {{ __("Nome", "unities") }}
                    </td>
                    <td>
                      <v-text-field
                        v-model="advanced_filters.name"
                        clearable
                        dense
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      {{ __("Email", "unities") }}
                    </td>
                    <td>
                      <v-text-field
                        v-model="advanced_filters.email"
                        clearable
                        dense
                      ></v-text-field>
                    </td>
                  </tr>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="handleResetFilter" text>{{
                  __("Limpar", "unities")
                }}</v-btn>
                <v-btn type="submit" color="primary">{{
                  __("Pesquisar", "unities")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-menu>
      </v-col>
      <v-col class="text-right mb-4">
        <ScopeProvider scope="unities.add">
          <v-btn @click="handleOpenDialog()" color="primary">
            <v-icon left>mdi-plus</v-icon>{{ __("Nova unidade", "unities") }}
          </v-btn>
        </ScopeProvider>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="5"
        :disabled="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="__('A carregar', 'unities')"
        fixed-header
        max-height="500px"
      >
        <template v-slot:header.name>
          {{ __("Nome", "contact") }}
        </template>

        <template v-slot:header.email>
          {{ __("Email", "unities", 1) }}
        </template>

        <template v-slot:header.scheduling_qty>
          {{ __("Agendamentos", "unities", 1) }}
        </template>

        <template v-slot:header.team_name>
          {{ __("Equipe", "unities", 1) }}
        </template>

        <template v-slot:header.status>
          {{ __("Status", "unities", 1) }}
        </template>

        <template v-slot:item.name="{ item }">
          <div>
            <router-link
              :to="{ name: 'unity_details', params: { id: item.id } }"
            >
              {{ item.name }}
            </router-link>
            <UnityRating :unity="item" :statusRating="false" />
          </div>
        </template>
        <template v-slot:item.team_name="{ item }">
          <v-chip
            v-if="item.team_id"
            :to="`/teams/${item.team_id}`"
            small
            color="indigo"
            dark
            label
          >
            {{ item.team_name }}
          </v-chip>
          <span v-else class="grey--text">{{ __("Nenhum", "unities") }}</span>
        </template>
        <template v-slot:item.is_user_active="{ item }">
          <v-chip
            v-if="item.is_user_active"
            class="status-chip"
            dark
            label
            small
            color="green darken-1"
            >{{ __("Ativo", "unities") }}</v-chip
          >
          <v-chip v-else small class="status-chip" dark label color="red">{{
            __("Bloqueado", "unities")
          }}</v-chip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="unity_dialog" max-width="500" persistent>
      <unity-form
        v-model="unity"
        :title="__('Dados da Unidade', 'unities')"
        @submit="handleSubmitForm"
        @close="handleCloseDialog"
      ></unity-form>
    </v-dialog>

    <UnityZonesDialog ref="unityZonesDialog" />
  </container>
</template>

<script>
import UnityForm from "../components/forms/UnityForm";
import UnityRating from "../components/Unities/UnityRating";
import { index as indexUnities } from "../services/unities";
import ScopeProvider from "@/components/ScopeProvider";
import i18n from "@/mixins/i18n";
import UnityZonesDialog from "@/components/Unities/UnityZonesDialog";

export default {
  name: "unities",
  components: { UnityForm, ScopeProvider, UnityZonesDialog, UnityRating },
  mixins: [i18n],
  data: () => ({
    items: [],
    headers: [
      { text: "Nome", value: "name" },
      { text: "Email", value: "email" },
      { text: "", value: "rating", align: "center" },
      { text: "Equipe", value: "team_name" },
      { text: "Status", value: "is_user_active", align: "center", width: 100 },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    loading: false,
    totalItems: 0,
    options: {
      itemsPerPage: 10,
      sortBy: ["name"],
      sortDesc: [],
    },
    unity_dialog: false,
    filter: "",
    advanced_filter: false,
    advanced_filters: {
      admin_rating: [],
      is_active: [],
      scheduling_qty_operator: ">",
    },
    advanced_filtered: false,
    unity: {},
  }),
  methods: {
    handleResetFilter() {
      this.advanced_filters = {
        admin_rating: [],
        is_active: [],
        scheduling_qty_operator: ">",
      };
      this.advanced_filter = false;
      this.getUnities();
    },
    handleSubmitForm(event) {
      this.getUnities();
    },
    handleOpenDialog() {
      this.unity_dialog = true;
    },
    handleCloseDialog() {
      this.unity_dialog = false;
    },
    handleAdvanceFilter() {
      this.getUnities();
      this.advanced_filter = false;
    },
    checkAdvancedFilterInParams(params) {
      return (
        !!params.name ||
        !!params.email ||
        params.admin_rating.length ||
        params.is_active.length ||
        !isNaN(params.scheduling_qty)
      );
    },
    async getUnities() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loading = true;
      this.items = [];
      try {
        const params = {
          paginate: true,
          orderBy: sortBy[0] ? sortBy[0] : "",
          sortDesc: sortDesc[0] ? sortDesc[0] : "",
          page,
          perPage: itemsPerPage,
          filter: this.filter || "",
          name: this.advanced_filters.name,
          email: this.advanced_filters.email,
          admin_rating: this.advanced_filters.admin_rating,
          is_active: this.advanced_filters.is_active,
          scheduling_qty_operator:
            this.advanced_filters.scheduling_qty_operator,
        };

        const data = await indexUnities(params);

        this.items = data.data;
        this.totalItems = data.total;
        this.advanced_filtered = this.checkAdvancedFilterInParams(params);
      } catch (error) {
        this.items = [];
        this.totalItems = 0;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getUnities();
      },
      deep: true,
    },
    filter: function (value) {
      if (value == null) this.getUnities();
    },
  },
};
</script>
